<template>
  <v-container>
    <v-row v-if="isListLoading">
      <div class="d-flex flex-row flex-grow-1 justify-center">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-row>

    <div class="d-flex flex-column flex-grow-1" v-else>
      <div class="d-flex flex-row py-1">
        <PageTitle
          text="All Users"
          class=" font font-weight-bold font-size-lg"
        />
      </div>
      <div class="d-flex flex-row mb-2">
        <v-btn
          :to="{
            name: 'master.users.add',
            query: { action: 'add' }
          }"
          class="font font-weight-medium font-size-md "
          color="primary"
        >
          Add New User <i class="material-icons-outlined">add</i>
        </v-btn>
      </div>
      <div class="d-flex flex-row flex-grow-1 flex-wrap mt-4">
        <div class="d-flex flex-column flex-grow-1">
          <v-data-table
            class="font font-weight-medium font-size-md box-shadow-light"
            :headers="headers"
            :items="users"
            :footer-props="{
              itemsPerPageOptions: [15, 20, 30]
            }"
          >
            <template #item.isActive="{item}">
              <v-chip
                label
                :color="item.isActive ? '#9efb9e66' : '#f98c6b'"
                class="ft font-weight-medium mr-2 text-uppercase"
                small
              >
                <span
                  :style="{
                    color: item.isActive ? 'rgba(3, 91, 3, 0.83)' : '#862c00'
                  }"
                  class="font-weight-bold"
                  >{{ item.isActive ? "Active" : "Inactive" }}</span
                >
              </v-chip>
            </template>
            <template #item.actions="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{
                      name: 'master.users.add',
                      query: {
                        action: 'edit',
                        id: item.id
                      }
                    }"
                    v-on="on"
                    icon
                    color="primary"
                  >
                    <i class="material-icons-outlined font-size-sm">edit</i>
                  </v-btn>
                </template>
                <span class="font font-weight-medium font-size-sm"
                  >Edit User</span
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: {
    PageTitle: () => import(`../../../components/PageTitle`)
  },
  computed: {
    ...mapGetters({
      users: "users/getListOfUsers",
      isListLoading: "getIsListLoading"
    })
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "mobile" },
        { text: "Account Status", value: "isActive" },
        {
          text: "Actions",
          value: "actions"
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("users/list");
  }
};
</script>
